import { createRouter, createWebHistory } from 'vue-router'
import ShareableSurveyView from '@/components/ShareableSurveyView.vue'
import HomeView from '@/components/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
  },
  {
    path: '/survey/:survey_id',
    name: 'ShareableSurveyView',
    component: ShareableSurveyView,
    props: (route) => ({
      surveyId: route.params.survey_id,
      isPreview: route.query.isPreview === 'true'
    }),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
