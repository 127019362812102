<template>
  <div class="flex items-center justify-center h-screen bg-zinc-100">
    <div v-if="loading" class="loader"></div>
    <div v-else-if="!loading && contentData.length === 0" class="bg-white rounded-2xl px-4 py-12 sm:px-20 w-full shadow max-w-2xl">
      <h1 class="text-xl font-semibold text-gray-800 mb-4">Survey Not Found</h1>
    </div>
    <ChatBox v-if="!loading && contentData && contentData.length > 0" :responseId="responseId" class="" :contentData="contentData" />
    <div
      class="absolute bottom-2 w-auto text-center bg-gray-900 rounded-full rounded-tl-sm mx-auto max-w-xs px-4 py-2 text-sm font-medium flex items-center justify-center shadow-lg">
      <img src="../assets/sondar-logomark-white.svg" alt="" class="inline-block mr-2 w-3">
      <a href="https://sondar.ai" target="_blank" class="inline-block text-white underline">Powered by Sondar</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ChatBox from './ChatBox.vue'

export default {
  components: { ChatBox },
  mounted() {
    this.fetchSurveyData();
  },
  props: {
    surveyId: {
      type: String,
      required: true
    },
    isPreview: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      contentData: [],
      responseId: null,
      loading: false,
    }
  },
  methods: {
    async fetchSurveyData() {
      try {
        this.loading = true;
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/initiate-response-v1`, {
          study_id: this.surveyId,
          channel: "Shareable Link",
          is_preview: this.isPreview
        }, {
          headers: {
            'Authorization': 'Bearer 19117293269db4af7d7b7083c3cfc7c5'
          }
        });

        if (response.data && response.data.blocks) {
          this.contentData = response.data.blocks;
          this.responseId = response.data.response_id;
        }
      } catch (error) {
        console.error('Error fetching survey data:', error);
      } finally {
        this.loading = false;
      }
    },
    noThanksAction() {
      console.log('No Thanks clicked');
    },
    letsGoAction() {
      console.log('Let\'s go clicked');
    }
  }
}
</script>

<style scoped>
.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #5051BA;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>