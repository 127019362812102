<template>
    <div class="chat-container w-full">
        <div class="chat-messages flex flex-col" ref="chatMessages">
            <div v-for="(message, index) in messages" :key="index" :class="['message', message.type]">
                <img src="../assets/robot-face.png" alt="logo">
                <p>{{ message.text }}</p>
            </div>
            <div v-if="(!openingMessage && !chatEnded) || waitingForResponse" class="message system typing-indicator">
                <img src="../assets/robot-face.png" alt="logo">
                <div class="bg-indigo-700 text-white px-5 py-2 rounded-xl rounded-tl-sm">
                    <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
                </div>
            </div>
        </div>

        <transition name="fade" @after-leave="handleChatEnd">
            <div v-if="!chatEnded" class="chat-input flex p-1">
                <input v-model="userInput" @keyup.enter="sendMessage" type="text" placeholder="Type here..." class="mr-2 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:outline-none sm:text-sm/6">
                <button @click="sendMessage" class=" rounded-lg bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                    </svg>
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'axios';
import { nextTick } from 'vue';

export default {
    name: 'FollowUp',
    data() {
        return {
            messages: this.openingMessage ? [{ text: this.openingMessage, type: 'system' }] : [],
            userInput: '',
            chatEnded: false,
            questionRem: this.fuQuestionsRemaining,
            waitingForResponse: false,
            conversationStarted: false,
        };
    },
    props: {
        openingMessage: {
            type: String,
            default: null
        },
        responseId: {
            type: String,
            required: true
        },
        blockId: {
            type: String,
            required: true
        },
        fuQuestionsRemaining: {
            type: Number,
            required: false,
        }
    },
    watch: {
        openingMessage(newMessage) {
            this.messages = [{ text: newMessage, type: 'system' }];
            this.scrollToBottom();
        }
    },
    methods: {
        async postResponse(message) {
            const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/save-response-item-v1`;
            const data = {
                responseID: this.responseId,
                blockID: this.blockId,
                isFollowup: this.questionRem != -1,
                responseText: message,
                fuQuestionsRemaining: this.questionRem < 0 ? 0 : this.questionRem 
            };
            this.waitingForResponse = true;
            if(this.questionRem >= 0) this.questionRem--;

            const headers = {
                Authorization: 'Bearer 19117293269db4af7d7b7083c3cfc7c5',
                'Content-Type': 'application/json'
            };

            try {
                const response = await axios.post(apiUrl, data, { headers });
                if (!this.conversationStarted) {
                    this.conversationStarted = true;
                    this.handleConversationStarted();
                }
                this.waitingForResponse = false;
                if(this.questionRem == -1) {
                    this.messages.push({
                        text: "Thanks for your feedback, let's move on.",
                        type: 'system'
                    });
                    this.scrollToBottom();
                    this.chatEnded = true;
                } else {
                    this.messages.push({
                        text: response.data.message,
                        type: 'system'
                    })
                    this.scrollToBottom();
                }
                console.log('API response:', response.data);
            } catch (error) {
                this.waitingForResponse = false;
                console.error('Error making API request:', error);
            }
        },
        scrollToBottom() {
            nextTick(() => {
                const chatMessages = this.$refs.chatMessages;
                if (chatMessages) {
                    chatMessages.scrollTop = chatMessages.scrollHeight;
                }
            });
        },
        sendMessage() {
            if (this.userInput.trim() !== '') {
                this.messages.push({ text: this.userInput, type: 'user' });
                this.postResponse(this.userInput);
                this.userInput = '';
                this.scrollToBottom();
            }
        },
        handleChatEnd() {
            this.$emit('chatEnded');
        },
        handleConversationStarted() {
            this.$emit('conversationStarted');
        }
    }
}
</script>

<style scoped>

/* .chat-input {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background-color: #ffffff;
    border-top: 1px solid #e0e0e0;
}

.chat-input input {
    flex: 1;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 0.9rem;
    outline: none;
}

.chat-input button {
    background: none;
    border: none;
    color: #4a90e2;
    cursor: pointer;
    margin-left: 0.5rem;
}

.chat-input button svg {
    fill: #4a90e2;
} */

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.typing-indicator {
    display: flex;
    align-items: center;
    color: #333;
}

.dot {
    display: inline-block;
    margin: 0 2px;
    border-radius: 50%;
    font-size: 1.5rem;
    animation: bounce 0.6s infinite alternate;
    color: white;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    to {
        transform: translateY(-30%);
    }
}
</style>