<template>
  <div class="chat-box bg-white rounded-2xl px-4 py-12 sm:px-20 w-full shadow max-w-2xl">
    <svg v-if="isSDK && canDismiss" @click="closeWidget" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" class="size-6 absolute top-2 right-2 cursor-pointer stroke-gray-400 hover:stroke-gray-700">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>

    <transition name="fade-slide">
      <div class="chat-content w-full flex flex-col items-center mx-auto" v-if="currentContent">
        <p class="progress-text text-gray-500 text-sm font-medium">{{ currentIndex + 1 }} of {{ contentData.length }}
        </p>
        <div class="w-full flex flex-col items-center" v-if="currentContent.type === 'Message'">
          <p class="block-headline">{{ currentContent.question }}</p>
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'Explore'">
          <FollowUp @conversationStarted="handleConversationStarted" @chatEnded="handleChatEnd" :responseId="responseId"
            :blockId="currentContent.id" :openingMessage="currentContent.question"
            :key="`${currentContent.id}-${currentIndex}`"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'Select'">
          <p class="block-headline">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description">{{ currentContent.description }}</p>
          <div class="options">
            <div v-for="(choice, index) in currentContent.choices" :key="index"
              :class="['option', { selected: selectedChoice === choice }]"
              @click="!showFollowUp && selectOption(choice)">
              <input type="radio" :name="currentContent.id" :value="choice" v-model="selectedChoice"
                :disabled="showFollowUp" />
              <label>{{ choice }}</label>
            </div>
          </div>
          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id"
            :key="`${currentContent.id}-${currentIndex}`"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'Rate'">
          <p class="block-headline">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description">{{ currentContent.description }}</p>
          <div class="inline-block ratings">
            <div class="flex flex-row gap-x-1 sm:gap-x-3 pb-1">
              <label v-for="n in currentContent.max_value" :key="n" class="rating-label">
                <input type="radio" v-model="selectedChoice" :name="currentContent.id" :value="n"
                  class="rating-input" />
                <!-- <span class="rating-star">&#9733;</span> -->
                <span :class="{ 'rating-text': true, 'rating-selected': selectedChoice === n }">{{ n }}</span>
              </label>
            </div>
            <div class="flex justify-between text-gray-500 font-medium mt-3">
              <div>{{ currentContent.min_label }}</div>
              <div>{{ currentContent.max_label }}</div>
            </div>
          </div>
          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id"
            :key="`${currentContent.id}-${currentIndex}`"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'CSAT'">
          <p class="block-headline">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description">{{ currentContent.description }}</p>
          <div class="inline-block ratings">
            <div class="flex flex-row gap-x-1 sm:gap-x-3 pb-1">
              <label v-for="n in currentContent.max_value" :key="n" class="rating-label">
                <input type="radio" v-model="selectedChoice" :name="currentContent.id" :value="n" class="rating-input" />
                

                <span v-if="currentContent.max_value === 5" :class="{'rating-csat': true, 'rating-selected': selectedChoice === n}">
                  {{ ['😠', '😟', '😐', '🙂', '😍'][n - 1] }}
                </span>

                <span v-if="currentContent.max_value === 4" :class="{'rating-csat': true, 'rating-selected': selectedChoice === n}">
                  {{ ['😠', '😟', '🙂', '😍'][n - 1] }}
                </span>

                <span v-if="currentContent.max_value === 3" :class="{'rating-csat': true, 'rating-selected': selectedChoice === n}">
                  {{ ['😠', '😐', '😍'][n - 1] }}
                </span>

                <span v-if="currentContent.max_value === 2" :class="{'rating-csat': true, 'rating-selected': selectedChoice === n}">
                  {{ ['👎', '👍'][n - 1] }}
                </span>

              </label>
            </div>
          </div>

          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :key="`${currentContent.id}-${currentIndex}`"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id" 
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>

        <div v-else-if="currentContent.type === 'Multi Choice'">
          <p class="block-headline">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description">{{ currentContent.description }}</p>
          <div class="options">
            <div v-for="(choice, index) in currentContent.choices" :key="index"
              :class="['option', { selected: selectedChoice.includes(choice) }]"
              @click="!showFollowUp && toggleChoice(choice)">
              <input type="checkbox" :name="currentContent.id" :value="choice" v-model="selectedChoice"
                :disabled="showFollowUp" />
              <label>{{ choice }}</label>
            </div>
          </div>
          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id"
            :key="`${currentContent.id}-${currentIndex}`"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>
        <button v-if="showNextButton" :disabled="!isButtonEnabled"
          :class="['btn-action', { 'btn-disabled': !isButtonEnabled }]" class="btn-action" @click="nextContent">{{
            currentContent.button_label }}</button>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import FollowUp from './FollowUp.vue';

export default {
  name: 'ChatBox',
  components: {
    FollowUp
  },
  props: {
    contentData: {
      type: Array,
      required: true
    },
    responseId: {
      type: String,
      required: true
    },
    surveyId: {
      type: String,
      required: false
    },
    isSDK: {
      type: Boolean,
      default: false
    },
    canDismiss: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      currentIndex: 0,
      selectedChoice: null,
      hasConversationStarted: false,
      hasChatEndedForCurrentQuestion: false,
      showFollowUp: false,
      followUpQuestionFromResponse: null,
    };
  },
  computed: {
    showNextButton() {
      if(this.isLastQuestion() && this.contentData[this.currentIndex].button_url == "") {
        return false;
      }
      return true;
    },
    isButtonEnabled() {
      let current = this.contentData[this.currentIndex];
      if (!current.required) return true;
      if (current.type === "Message") {
        return true;
      }
      if (current.type === "Explore") {
        return this.hasConversationStarted;
      }
      if (["Select", "Rate", "CSAT", "Multi Choice"].includes(current.type)) {
        return this.selectedChoice;
      }
      return false;
    },
    currentContent() {
      return this.contentData[this.currentIndex];
    }
  },
  methods: {
    isLastQuestion() {
      return this.currentIndex === this.contentData.length - 1;
    },
    closeWidget() {
      const sondarWidget = document.getElementById('sondar-widget');
      if(sondarWidget) {
        sondarWidget.style.display = 'none';
      }
    },
    async postResponse(currentBlock) {
      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/save-response-item-v1`;
      const data = {
        responseID: this.responseId,
        blockID: currentBlock.id,
        isFollowup: false,
        fuQuestionsRemaining: currentBlock.followup_questions,
      };

      if (['Rate', 'CSAT'].includes(currentBlock.type)) {
        data.responseNumber = this.selectedChoice;
      } else if (currentBlock.type === 'Select') {
        data.responseChoices = [this.selectedChoice];
      } else if (currentBlock.type === 'Multi Choice') {
        data.responseChoices = this.selectedChoice;
      }

      const headers = {
        Authorization: 'Bearer 19117293269db4af7d7b7083c3cfc7c5',
        'Content-Type': 'application/json'
      };

      try {
        const response = await axios.post(apiUrl, data, { headers });
        console.log('API response:', response.data);
        if (currentBlock.followup_questions) this.followUpQuestionFromResponse = response.data.message;
      } catch (error) {
        console.error('Error making API request:', error);
      }
    },
    handleChatEnd() {
      console.log("Chat has ended.");
      this.hasChatEndedForCurrentQuestion = true;
    },
    handleConversationStarted() {
      this.hasConversationStarted = true;
    },
    nextContent() {
      if(this.contentData[this.currentIndex].button_url) {
        let url = this.contentData[this.currentIndex].button_url;
        if (!/^https?:\/\//i.test(url)) {
            url = 'https://' + url;
        }
        window.open(url, '_blank');
      }
      if(this.isLastQuestion()) {
        return;
      }
      const current = this.contentData[this.currentIndex];
      if (
        current.type !== "Explore" &&
        current.type !== "Message" &&
        !this.showFollowUp &&
        this.selectedChoice &&
        (typeof this.selectedChoice === 'number' || typeof this.selectedChoice === 'string' || (Array.isArray(this.selectedChoice) && this.selectedChoice.length > 0))
      ) {
        this.postResponse(current);
      }
      const isSelectableType = ["Select", "Rate", "CSAT", "Multi Choice"].includes(current.type);
      if (
        isSelectableType &&
        current.followup_questions &&
        !this.showFollowUp &&
        this.selectedChoice &&
        (typeof this.selectedChoice === 'number' || typeof this.selectedChoice === 'string' || (Array.isArray(this.selectedChoice) && this.selectedChoice.length > 0))
      ) {
        this.showFollowUp = true;
        return;
      }

      // Move to next question if no follow-up is required or chat ended
      this.currentIndex++;
      this.hasChatEndedForCurrentQuestion = false;
      this.showFollowUp = false;
      this.selectedChoice = this.contentData[this.currentIndex].type === "Multi Choice" ? [] : null;
      this.followUpQuestionFromResponse = null;

      if (this.currentIndex >= this.contentData.length - 1) {
        this.surveyCompleted();
      }
    },
    surveyCompleted() {
      if (this.isSDK) {
        this.manageSurveyCookie();
      }
    },
    manageSurveyCookie() {
      function getCookie(name) {
        let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        return match ? JSON.parse(decodeURIComponent(match[2])) : null;
      }

      function setCookie(name, value, days) {
        let expires = '';
        if (days) {
          let date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + encodeURIComponent(JSON.stringify(value)) + expires + '; path=/';
      }

      let cookie = getCookie('sondar_surveys');
      setCookie('sondar_surveys', cookie ? { ...cookie, [this.surveyId]: true } : { [this.surveyId]: true }, 400);
    },
    selectOption(choice) {
      this.selectedChoice = choice;
    },
    toggleChoice(choice) {
      if (this.selectedChoice.includes(choice)) {
        this.selectedChoice = this.selectedChoice.filter(c => c !== choice);
      } else {
        this.selectedChoice.push(choice);
      }
    }
  }
}
</script>

<style scoped>
.option input[type="radio"],
.option input[type="checkbox"] {
  display: none;
}

.option label {
  cursor: pointer;
}

textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 0.5rem;
  resize: none;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>