<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100">
    <h1 class="text-2xl font-bold">Welcome to the Home View</h1>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>
